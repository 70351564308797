import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { blockSpacing } from "../lib/getBlockSpacing";

const CountdownTimer = ({
	countdownDate,
	textColor,
	backgroundColor,
	borderColor,
	blockPadding,
	mobileBlockPadding,
	blockMargin,
	mobileBlockMargin,
}) => {
	interface Date {
		[key: string]: number[];
	}

	const [currentDate, setCurrentDate] = useState(new Date());
	const [remaining, setRemaining] = useState<Date>();

	function getRemaining(countdown, current) {
		const remaining: Date = {
			days: [0],
			hours: [0],
			minutes: [0],
			seconds: [0],
		};
		//first value in array is whole number, the second value in array is percentage. This is for progress component, and is needed when mapping over the array.
		remaining.seconds[0] = (countdown.getTime() - current.getTime()) / 1000;

		remaining.days[0] = Math.floor(remaining.seconds[0] / 86400);
		remaining.days[1] = (remaining.days[0] / 365) * 100;
		remaining.seconds[0] = remaining.seconds[0] % 86400;

		remaining.hours[0] = Math.floor(remaining.seconds[0] / 3600);
		remaining.hours[1] = (remaining.hours[0] / 24) * 100;
		remaining.seconds[0] = remaining.seconds[0] % 3600;

		remaining.minutes[0] = Math.floor(remaining.seconds[0] / 60);
		remaining.minutes[1] = (remaining.minutes[0] / 60) * 100;

		remaining.seconds[0] = Math.floor(remaining.seconds[0] % 60);
		remaining.seconds[1] = (remaining.seconds[0] / 60) * 100;

		return remaining;
	}

	useEffect(() => {
		const interval = setInterval(() => {
			setCurrentDate(new Date());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() => {
		setRemaining(getRemaining(new Date(countdownDate), currentDate));
	}, [currentDate]);

	if (!remaining) return null;

	return (
		<Box
			maxWidth="1200px"
			mx="auto"
			width="full"
			textAlign="center"
			{...blockSpacing(
				blockPadding,
				mobileBlockPadding,
				blockMargin,
				mobileBlockMargin
			)}
		>
			<Flex wrap="wrap" display="flex" justify="space-around">
				{Object.keys(remaining).map((item, index) => (
					<Flex
						mb={{ base: "30px", md: "0px" }}
						position="relative"
						key={index}
						direction="column"
						justify="center"
					>
						{/*Gives additional styling to the CircularProgress component*/}
						<style>
							{`
								.css-ol3i12, chakra-progress__track {
									stroke: none;
								}
								`}
						</style>
						<CircularProgress
							border={"1px solid " + borderColor}
							borderRadius="50%"
							overflow="hidden"
							size="185px"
							thickness="84px"
							color={backgroundColor}
							value={remaining[item][1]}
						>
							<CircularProgressLabel
								color={textColor}
								fontSize="72px"
								fontWeight="300"
							>
								{remaining[item][0]}
							</CircularProgressLabel>
							<Box
								borderRadius="50%"
								border={"1px solid " + borderColor}
								pos="absolute"
								top="50%"
								left="50%"
								transform="translate(-50%, -50%);"
								height="85%"
								width="85%"
								zIndex="25"
							></Box>
						</CircularProgress>
						<Text fontSize="16px" mt="12px" fontWeight="500">
							{remaining[item][0] == 1
								? item.toUpperCase().slice(0, -1)
								: item.toUpperCase()}
						</Text>
					</Flex>
				))}
			</Flex>
		</Box>
	);
};

export default CountdownTimer;
