import { ArrowLeft, ArrowRight } from "@abnormalComponents/Icons";
import PageSections from "@abnormalComponents/PageSections";
import clsx from "clsx";
import { useState } from "react";
import { A11y, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ContentSlider = ({ children }) => {
	const [isBeginning, setIsBeginning] = useState(true);
	const [isEnd, setIsEnd] = useState(false);
	const [swiper, setSwiper] = useState(null);

	const handleSlideChange = () => {
		setIsBeginning(swiper.isBeginning);
		setIsEnd(swiper.isEnd);
	};

	const NavButton = ({ direction = "next" }) => {
		const handleClick = () => {
			if (direction === "next") swiper.slideNext();
			if (direction === "prev") swiper.slidePrev();
		};

		return (
			<button
				className={clsx(
					"w-12 h-12",
					"bg-white-01",
					"rounded-full",
					"flex items-center justify-center",
					"absolute top-0 z-30 -translate-y-1/2",
					"transition-all",
					"hover:translate-x-2",
					{
						"opacity-0 pointer-events-none":
							(direction === "prev" && isBeginning) ||
							(direction === "next" && isEnd),
						"right-4 lg:right-7.5": direction === "next",
						"left-4 lg:left-7.5": direction === "prev",
					}
				)}
				onClick={handleClick}
			>
				{direction === "next" ? <ArrowRight /> : <ArrowLeft />}
			</button>
		);
	};

	return (
		<div className={clsx("ContentSlider w-full")}>
			<div className={clsx("ContentSlider__content")}>
				<Swiper
					centeredSlides
					modules={[Pagination, A11y]}
					watchOverflow
					onSlideChange={handleSlideChange}
					onSwiper={setSwiper}
					grabCursor
					a11y={{
						prevSlideMessage: "Previous slide",
						nextSlideMessage: "Next slide",
					}}
					pagination={{ clickable: true }}
					className={clsx("ContentSlider__slides", "pb-20")}
					slidesPerView={1}
				>
					{children &&
						children.map((slide, index) => {
							return (
								<SwiperSlide
									key={slide.id}
									className={clsx("ContentSlider__slide")}
								>
									<PageSections sections={slide.children} isPageModule={true} />
								</SwiperSlide>
							);
						})}
				</Swiper>
				{children.length && (
					<div className="controls">
						<NavButton direction={"prev"} />
						<NavButton direction={"next"} />
					</div>
				)}
			</div>
		</div>
	);
};

export default ContentSlider;
