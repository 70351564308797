import PageSections from "@abnormalComponents/PageSections";
import { headerHeight } from "@abnormalLib/headerHeight";
import { Box, Flex } from "@chakra-ui/react";
import clsx from "clsx";

const SideBySideBlade = ({
	backgroundColor,
	backgroundImage,
	mobileBackgroundImage,
	maxWidth,
	verticalAlignment,
	reverseOrderOnMobile,
	children,
	isFirstPageSection,
	contentSpacing,
	columnSpacing = "md",
	bladeVerticalPadding,
}) => {
	return (
		<Box
			className="SideBySideBlade relative"
			bgColor={backgroundColor}
			w="full"
		>
			<Flex
				className={clsx("SideBySideBlade__content relative w-full")}
				maxW={maxWidth}
				justify={
					children[0]?.constrainToMainContainer &&
					children[1]?.constrainToMainContainer
						? "center"
						: children[0]?.constrainToMainContainer
						? "flex-end"
						: children[1]?.constrainToMainContainer
						? "flex-start"
						: "center"
				}
				mx="auto"
				align={verticalAlignment}
				px={5}
				py={
					bladeVerticalPadding !== "none" && {
						base: "sm",
						md: bladeVerticalPadding,
					}
				}
				pt={{
					base: isFirstPageSection && headerHeight ? headerHeight + 1 : null,
					md: isFirstPageSection && headerHeight ? headerHeight + 32 : null,
				}}
				flexDir={{
					base: reverseOrderOnMobile ? "column-reverse" : "column",
					md: "row",
				}}
				zIndex={1}
				gap={{ base: "sm", md: columnSpacing }}
			>
				{children &&
					children.map((column, index) => {
						return (
							<Flex
								w={{
									base: "100%",
									md: "50%",
								}}
								key={index}
								flexDirection="column"
								gap={contentSpacing}
								className="SideBySideBlade__column"
								maxW={column.constrainToMainContainer ? 540 : null}
							>
								{column.children && <PageSections sections={column.children} />}
							</Flex>
						);
					})}
			</Flex>
			{!!backgroundImage && (
				<Box
					className="SideBySideBlade__background"
					bgImage={backgroundImage?.url}
					bgPos="center"
					bgSize="cover"
					bgRepeat="no-repeat"
					maxWidth={"2100px"}
					pos="absolute"
					mx="auto"
					inset="0"
					zIndex={0}
					display={{ base: "hidden", md: "block" }}
				/>
			)}
			{!!mobileBackgroundImage && (
				<Box
					className="SideBySideBlade__mobileBackground"
					bgImage={mobileBackgroundImage?.url}
					bgPos="center"
					bgSize="cover"
					bgRepeat="no-repeat"
					maxWidth={"2100px"}
					pos="absolute"
					mx="auto"
					inset="0"
					zIndex={0}
					display={{ base: "block", md: "hidden" }}
				/>
			)}
		</Box>
	);
};

export default SideBySideBlade;
