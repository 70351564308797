import { Box, ButtonGroup, Flex, Heading } from "@chakra-ui/react";
import { Prose } from "@nikolovlazar/chakra-ui-prose";
import clsx from "clsx";
import parse from "html-react-parser";
import sample from "lodash/sample";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { InView } from "react-intersection-observer";

import { blockSpacing } from "@sharedLib/getBlockSpacing";
import { getStyleObjectFromString } from "@sharedLib/getStyleObjectFromString";

import AwardsGrid from "@abnormalComponents/AwardsGrid";
import BodyTextColumns from "@abnormalComponents/BodyTextColumns";
import CallToAction from "@abnormalComponents/CallToAction";
import CareersList from "@abnormalComponents/CareersList";
import ContentBlocks from "@abnormalComponents/ContentBlocks";
import ContentList from "@abnormalComponents/ContentList";
import DefenceInDepth from "@abnormalComponents/DefenceInDepth";
import Embed from "@abnormalComponents/Embed";
import EntryCarouselSection from "@abnormalComponents/EntryCarouselSection";
import EntryGrid from "@abnormalComponents/EntryGrid";
import FormSection from "@abnormalComponents/FormSection";
import Header from "@abnormalComponents/Header";
import Hero from "@abnormalComponents/Hero";
import IframeEmbed from "@abnormalComponents/IframeEmbed";
import IllustrationGrid from "@abnormalComponents/IllustrationGrid";
import ImageGrid from "@abnormalComponents/ImageGrid";
import LogoCarousel from "@abnormalComponents/LogoCarousel";
import LogoGrid from "@abnormalComponents/LogoGrid";
import PageSections from "@abnormalComponents/PageSections";
import Quotation from "@abnormalComponents/Quotation";
import ReviewsRow from "@abnormalComponents/ReviewsRow";
import SideBySide from "@abnormalComponents/SideBySide";
import Sitemap from "@abnormalComponents/Sitemap";
import SitemapItem from "@abnormalComponents/SitemapItem";
import StatsGrid from "@abnormalComponents/StatsGrid";
import Testimonials from "@abnormalComponents/Testimonials";
import VideoEmbed from "@abnormalComponents/VideoEmbed";

import Accordion from "@sharedComponents/Accordion";
import Animation from "@sharedComponents/Animation";
import ButtonBlock from "@sharedComponents/ButtonBlock";
import CallToActionBlock from "@sharedComponents/CallToActionBlock";
import Column from "@sharedComponents/Column";
import Container from "@sharedComponents/Container";
import ContentSlider from "@sharedComponents/ContentSlider";
import CountdownTimer from "@sharedComponents/CountdownTimer";
import IconList from "@sharedComponents/IconList";
import ImageAndContent from "@sharedComponents/ImageAndContent";
import PageImage from "@sharedComponents/PageImage";
import Reviews from "@sharedComponents/Reviews";
import SideBySideBlade from "@sharedComponents/SideBySideBlade";
import Spacer from "@sharedComponents/Spacer";
import StatCounter from "@sharedComponents/StatCounter";
import TabsBlock from "@sharedComponents/TabsBlock";
import Tag from "@sharedComponents/Tag";

interface IProps {
	section?: any;
	gridPosts?: any[];
	forms?: any[];
	globals?: object;
  callToActions?: any[] | object;
	isFirst?: boolean;
	isFirstContainer?: boolean;
	subColumn?: boolean;
	gridMode?: boolean;
	sectionNav?: boolean;
	gridColumns?: number;
	containerMaxWidth?: string;
  columnWidth?: string;
  allReviews?: any[];
}

const DefaultBlocks = ({
	section,
	isFirst = false,
	subColumn = false,
	gridPosts = null,
	forms = null,
	globals = null,
	gridMode = false,
	gridColumns,
	containerMaxWidth,
	columnWidth,
	isFirstContainer = false,
	callToActions = null,
	allReviews,
	sectionNav = false,
}: IProps) => {
	let image;
	let imageBlur;
	let mobileImage;
	let mobileImageBlur;
	let backgroundImage;
	let backgroundImageBlur;
	let mobileBackgroundImage;
	let mobileBackgroundImageBlur;
	let icon;
	let heroImage;
	let heroImageBlur;
	let mobileHeroImage;
	let mobileHeroImageBlur;
	let backgroundOverlay;
	let backgroundOverlayBlur;
	let logo;
	let logoBlur;
	let mediaBackgroundImage;
	let form;
	let formData;
	let videoThumbnail;
	let videoThumbnailBlur;

	switch (section.typeHandle) {
		case "hero":
			heroImage = section.heroImage[0];
			heroImageBlur = section.heroImageBlur[0];
			mobileHeroImage = section.mobileHeroImage[0];
			mobileHeroImageBlur = section.mobileHeroImageBlur[0];
			return (
				<Hero
					eyebrowHeading={section.eyebrowHeading}
					heading={section.heading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					heroImage={heroImage}
					heroImageBlur={heroImageBlur}
					mobileHeroImage={mobileHeroImage}
					mobileHeroImageBlur={mobileHeroImageBlur}
					text={section.text}
					secondaryText={section.secondaryText}
					isFirstPageSection={isFirst}
					height={section.sectionHeight}
				/>
			);

		case "header":
			image = section.image[0];
			imageBlur = section.imageBlur[0];
			mobileImage = section.mobileImage[0];
			mobileImageBlur = section.mobileImageBlur[0];
			backgroundImage = section.backgroundImage[0];
			backgroundOverlay = section.backgroundOverlay[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			return (
				<Header
					eyebrowHeading={section.eyebrowHeading}
					heading={section.heading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					subHeadingSize={section.subHeadingSize}
					highlightColor={section.highlightColor}
					subHeading={section.subHeading}
					textAlignment={section.textAlignment}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					verticalPadding={section.verticalPadding}
					backgroundImage={backgroundImage}
					backgroundOverlay={backgroundOverlay}
					backgroundImageBlur={backgroundImageBlur}
					image={image}
					imageBlur={imageBlur}
					mobileImage={mobileImage}
					mobileImageBlur={mobileImageBlur}
					isFirstPageSection={isFirst}
					target={section.target}
					buttonTextColor={section.buttonTextColor}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					secondaryTarget={section.secondaryTarget}
					secondaryButtonBackgroundColor={
						section.secondaryButtonBackgroundColor
					}
					secondaryButtonTextColor={section.secondaryButtonTextColor}
					entries={section.entries}
					iconStrip={section.iconStrip}
					videoUrl={section.videoUrl}
					maxWidth={section.maxWidth}
				/>
			);

		case "sideBySide":
			image = section.image[0];
			icon = section.icon[0];
			mobileImage = section.mobileImage[0];
			backgroundImage = section.backgroundImage[0];
			mobileBackgroundImage = section.mobileBackgroundImage[0];
			backgroundOverlay = section.backgroundOverlay[0];
			mediaBackgroundImage = section.mediaBackgroundImage[0];

			form = !!section.form ? section.form[0] : null;

			formData =
				!!forms && form
					? forms.find(({ formId }) => formId === form.formDetails[0].formId)
							.formDetails
					: null;

			return (
				<SideBySide
					legacyBackgroundColor={section.legacyBackgroundColor}
					backgroundImage={backgroundImage}
					mobileBackgroundImage={mobileBackgroundImage}
					backgroundOverlay={backgroundOverlay}
					backgroundImageBlur={backgroundImageBlur}
					mobileBackgroundImageBlur={mobileBackgroundImageBlur}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					secondaryButtonBackgroundColor={
						section.secondaryButtonBackgroundColor
					}
					secondaryButtonTextColor={section.secondaryButtonTextColor}
					heading={section.heading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					headingColor={section.headingColor}
					image={image}
					icon={icon}
					indentText={section.indentText}
					centerContent={section.centerContent}
					mediaPosition={section.mediaPosition}
					mobileMediaPosition={section.mobileMediaPosition}
					mediaPadding={section.mediaPadding}
					mediaPaddingTop={section.mediaPaddingTop}
					mediaPaddingBottom={section.mediaPaddingBottom}
					mobileImage={mobileImage || image}
					target={section.target}
					secondaryTarget={section.secondaryTarget}
					text={section.text}
					textColor={section.textColor}
					textPaddingTop={section.textPaddingTop}
					textPaddingMediaSide={section.textPaddingMediaSide}
					videoUrl={section.videoUrl}
					videoStyle={section.videoStyle}
					isFirstPageSection={isFirst}
					overrideDefaultFormValues={section.overrideDefaultFormValues}
					formDetails={section.formDetails}
					form={form}
					formData={formData}
					formHeading={section.formHeading}
					showBackgroundGrid={section.showBackgroundGrid}
					fullWidth={section.fullWidth}
					wideContainer={section.wideContainer}
					leftAlignPlayButton={section.leftAlignPlayButton}
					mediaBackgroundImage={mediaBackgroundImage}
				/>
			);
		case "logoCarousel":
			return (
				<LogoCarousel
					logos={section.logos}
					legacyBackgroundColor={section.legacyBackgroundColor}
					verticalPadding={section.verticalPadding}
					slideHeight={section.slideHeight}
					slidesPerView={section.slidesPerView}
				/>
			);
		case "logoGrid":
			return (
				<LogoGrid
					logos={section.logos}
					legacyBackgroundColor={section.legacyBackgroundColor}
					verticalPadding={section.verticalPadding}
				/>
			);
		case "embedCode":
			return (
				<div className={clsx("max-w-screen-xl mx-auto")}>
					<Embed embedCode={section.embedCode} />
				</div>
			);
		case "illustrationGrid":
			return (
				<IllustrationGrid
					items={section.gridItems}
					heading={section.heading}
					subHeading={section.subHeading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					target={section.target}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
				/>
			);

		case "contentList":
			return (
				<ContentList
					items={section.contentList}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
				/>
			);
		case "statsGrid":
			backgroundImage = section.backgroundImage[0];
			backgroundOverlay = section.backgroundOverlay[0];
			backgroundImageBlur = section.backgroundImageBlur[0];

			return (
				<StatsGrid
					stats={section.stats}
					statsPosition={section.statsPosition}
					heading={section.heading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					text={section.text}
					backgroundImage={backgroundImage}
					backgroundOverlay={backgroundOverlay}
					backgroundImageBlur={backgroundImageBlur}
					legacyBackgroundColor={section.legacyBackgroundColor}
					statTextColor={section.statTextColor}
					textColor={section.textColor}
					labelColor={section.labelColor}
					target={section.target}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
				/>
			);

		case "awardsGrid":
			return <AwardsGrid awards={section.awardsGrid} />;
		case "testimonials":
			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			return (
				<Testimonials
					testimonials={section.testimonials}
					useLogoNavigation={section.useLogoNavigation}
					legacyBackgroundColor={section.legacyBackgroundColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					textColor={section.textColor}
					attributionColor={section.attributionColor}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					target={section.target}
				/>
			);
		case "entryCarousel":
			return <EntryCarouselSection section={section} />;
		case "entryGrid":
			const gridEntries = !!gridPosts
				? gridPosts.find(({ sectionId }) => sectionId === section.id)?.entries
				: null;

			return (
				<EntryGrid
					entries={gridEntries}
					indentMobile
					showAuthor={false}
					thumbnailAspectRatio="square"
					linkDirectlyToAsset={section.linkDirectlyToAsset}
				/>
			);
		case "spacer":
			return (
				<Spacer
					size={section.size}
					hideOnMobile={section.hideOnMobile}
					horizontalRule={section.horizontalRule}
					borderColor={section.borderColor}
					legacyBackgroundColor={section.legacyBackgroundColor}
				/>
			);
		case "callToActions":
			const targetCallToAction = sample(section.callToActions);

			if (!targetCallToAction) return null;

			image = targetCallToAction.image[0];
			imageBlur = targetCallToAction.imageBlur[0];
			mobileImage = targetCallToAction.mobileImage[0];
			mobileImageBlur = targetCallToAction.mobileImageBlur[0];
			backgroundImage = targetCallToAction.backgroundImage[0];
			backgroundImageBlur = targetCallToAction.backgroundImageBlur[0];

			return (
				<CallToAction
					legacyBackgroundColor={targetCallToAction.legacyBackgroundColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					legacyButtonBackgroundColor={
						targetCallToAction.legacyButtonBackgroundColor
					}
					buttonTextColor={targetCallToAction.buttonTextColor}
					heading={targetCallToAction.heading}
					headingTag={targetCallToAction.headingTag}
					headingSize={targetCallToAction.headingSize}
					image={image}
					indentText={targetCallToAction.indentText}
					mediaPosition={targetCallToAction.mediaPosition}
					mediaPaddingTop={targetCallToAction.mediaPaddingTop}
					//mediaPaddingBottom={targetCallToAction.mediaPaddingBottom}
					mobileImage={mobileImage || image}
					target={targetCallToAction.target}
					text={targetCallToAction.text}
					textColor={targetCallToAction.textColor}
					videoStyle={targetCallToAction.videoStyle}
					videoUrl={targetCallToAction.videoUrl}
					form={targetCallToAction.form ? targetCallToAction.form[0] : null}
				/>
			);
		case "videoEmbed":
			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];

			videoThumbnail = section.videoThumbnail[0];
			videoThumbnailBlur = section.videoThumbnailBlur[0];

			return (
				<VideoEmbed
					url={
						section.video && section.video[0]?.url
							? section.video[0].url
							: section.videoUrl
					}
                    transcript={section.videoTranscript}
                    transcriptTitle={section.transcriptTitle}
                    transcriptBackgroundColor={section.backgroundColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					videoStyle={section.videoStyle}
					iconColor={section.iconColor}
					videoThumbnail={videoThumbnail}
					//videoThumbnailBlur={videoThumbnailBlur}
					showPlayerControls={section.showPlayerControls}
					videoWidth={section.videoWidth}
					videoHeight={section.videoHeight}
					localVideo={section.video && section.video[0]?.url ? true : false}
				/>
			);

		case "contentBlocks":
			if (!section.contentBlocks) return null;
			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			return (
				<ContentBlocks
					heading={section.heading}
					subHeading={section.subHeading}
					headingTag={section.headingTag}
					headingSize={section.headingSize}
					blocks={section.contentBlocks}
					layout={section.layout}
					textColor={section.textColor}
					iconColor={section.iconColor}
					columns={parseInt(section.columns)}
					padding={section.padding}
					borderColor={section.borderColor}
					fullWidth={section.fullWidth}
					centerColumns={section.centerColumns}
					addGap={section.addGap}
					verticalPadding={section.verticalPadding}
					target={section.target}
					legacyButtonBackgroundColor={section.legacyButtonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					legacyBackgroundColor={section.legacyBackgroundColor}
				/>
			);

		case "quotation":
			return (
        <Quotation 
          quotation={section.quotation} 
          quotationStyle={section.quotationStyle} 
          attribution={section.attribution} 
          role={section.role} 
        />
      );

		case "iframeEmbed":
			return <IframeEmbed url={section.iframeUrl} />;

		case "defenceInDepth":
			return (
				<DefenceInDepth
					heading={section.heading}
					subHeading={section.subHeading}
					contentColumns={section.contentColumns}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
					highlightColor={section.highlightColor}
				/>
			);

		case "imageGrid":
			return (
				<ImageGrid
					items={section.imageGrid}
					legacyBackgroundColor={section.legacyBackgroundColor}
					columns={section.columns}
				/>
			);

		case "image":
			image = section.image[0];
			mobileImage = section.mobileImage[0];

			if (!image) return null;

			return (
				<PageImage
					image={image}
					mobileImage={mobileImage}
					//fullWidth={section.fullWidth}
					maxWidth={section.maxWidth}
					maxWidthPixels={section.maxWidthPixels}
					horizontalAlignment={section.horizontalAlignment}
					containerMaxWidth={containerMaxWidth}
					columnWidth={columnWidth}
					gridMode={gridMode}
					gridColumns={gridColumns}
					isFirstContainer={isFirstContainer}
					iconColor={section.iconColor}
					legacyBackgroundColor={section.legacyBackgroundColor}
					roundedImage={section.roundedImage}
					blockPadding={section.blockPadding}
					mobileBlockPadding={section.mobileBlockPadding}
					blockMargin={section.blockMargin}
					mobileBlockMargin={section.mobileBlockMargin}
				/>
			);

		case "sitemap":
			return (
				<Sitemap
					//textColor={section.textColor}
					//legacyBackgroundColor={section.legacyBackgroundColor}
					heading={section.heading}
					//level={section.level}
					id={section.id}
				>
					{section.children}
				</Sitemap>
			);

		case "sitemapItem":
			//console.log(section.children)
			return (
				<SitemapItem
					target={section.target}
					level={section.level}
					id={section.id}
				>
					{section.children}
				</SitemapItem>
			);

		case "bodyText":
			return (
				<>
					{!!section.bodyText && (
						<Prose
							as="div"
							textColor={section.textColor}
							w="100%"
							className="BodyText"
							textAlign={section.textAlign ? section.textAlign : null}
							maxW={section.maxWidth}
							{...blockSpacing(
								section.blockPadding,
								section.mobileBlockPadding,
								section.blockMargin,
								section.mobileBlockMargin
							)}
						>
							{parse(section.bodyText)}
						</Prose>
					)}
					{!section.bodyText && (
						<BodyTextColumns
							columns={section.bodyTextColumns}
							textColor={section.textColor}
							maxWidth={section.maxWidth}
							legacyBackgroundColor={section.legacyBackgroundColor}
						/>
					)}
				</>
			);

		case "button":
			if (!section.target) return null;
			return (
				<ButtonBlock
					target={section.target}
					verticalPadding={section.verticalPadding}
					buttonBackgroundColor={section.buttonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
					horizontalAlignment={section.horizontalAlignment}
				/>
			);

		case "pageModule":
			return (
				<Box
					className="PageModule"
					w="full"
					style={{ backgroundColor: section.legacyBackgroundColor }}
					sx={
						!!section.customCss && getStyleObjectFromString(section.customCss)
					}
				>
					{section.pageModule && (
						<PageSections
							sections={section.pageModule[0]?.pageSections}
							isPageModule
							globals={globals}
						/>
					)}
				</Box>
			);

		case "careersList":
			return <CareersList departmentId={section.departmentId} />;

		case "reviewsRow":
			return (
				<ReviewsRow
					borderType={section.borderType}
					borderColor={section.borderColor}
					legacyBackgroundColor={section.legacyBackgroundColor}
					textColor={section.textColor}
				>
					{section.children}
				</ReviewsRow>
			);

		case "container":
			backgroundImage = section.backgroundImage[0];
			backgroundOverlay = section.backgroundOverlay[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			backgroundOverlayBlur = section.backgroundOverlayBlur[0];
			return (
				<Container
					isFirstPageSection={isFirst}
					isFirstContainer={isFirstContainer}
					maxWidth={section.maxWidth}
					backgroundColor={section.backgroundColor}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					customCss={section.customCss}
					horizontalAlignment={section.horizontalAlignment}
					verticalAlignment={section.verticalAlignment}
					gridMode={section.gridMode}
					gridGap={section.gridGap}
					gridColumns={section.gridColumns}
					backgroundOverlay={backgroundOverlay}
					backgroundOverlayBlur={backgroundOverlayBlur}
					backgroundOverlayPosition={section.backgroundOverlayPosition}
					anchorName={section.anchorName}
					gridPosts={gridPosts}
					forms={forms}
					globals={globals}
					reverseOrderOnMobile={section.reverseOrderOnMobile}
					backgroundFullWidth={section.backgroundFullWidth}
					hideOnMobile={section.hideOnMobile}
					hideOnDesktop={section.hideOnDesktop}
					disableNavigationPadding={section.disableNavigationPadding}
					blockPadding={section.blockPadding}
					mobileBlockPadding={section.mobileBlockPadding}
					blockMargin={section.blockMargin}
					mobileBlockMargin={section.mobileBlockMargin}
					sectionNav={sectionNav}
				>
					{section.children}
				</Container>
			);
		case "column":
			backgroundImage = section.backgroundImage[0];
			backgroundImageBlur = section.backgroundImageBlur[0];
			return (
				<Column
					columnWidth={section.columnWidth}
					constrainToMainContainer={section.constrainToMainContainer}
					blockPadding={section.blockPadding}
					mobileBlockPadding={section.mobileBlockPadding}
					blockMargin={section.blockMargin}
					mobileBlockMargin={section.mobileBlockMargin}
					subColumn={subColumn}
					verticalAlignment={section.verticalAlignment}
					backgroundColor={section.backgroundColor}
					borderColor={section.borderColor}
					borderRadius={section.borderRadius}
					borderStyle={section.borderStyle}
					borderWidth={section.borderWidth}
					gridMode={gridMode}
					gridColumns={gridColumns}
					gridColumnSpan={section.gridColumnSpan}
					containerMaxWidth={containerMaxWidth}
					target={section.target}
					gridPosts={gridPosts}
					forms={forms}
					globals={globals}
					isFirstContainer={isFirstContainer}
					backgroundFullWidth={section.backgroundFullWidth}
					backgroundImage={backgroundImage}
					backgroundImageBlur={backgroundImageBlur}
					horizontalAlignment={section.horizontalAlignment}
					contentDirection={section.contentDirection}
					customCss={section.customCss}
					gridGap={section.gridGap}
				>
					{section.children}
				</Column>
			);
		case "heading":
			section.heading = eval(
				"`" + section.heading?.replace(/(?:\r\n|\r|\n)/g, "<br>") + "`"
			);
			const [inView, setInView] = useState(false);

			const router = useRouter();
			useEffect(() => {
				if (section.heading.includes("@{")) {
					const vars = router.query;
					section.heading = section.heading?.replace("@{", "${vars.");
				}
			});

			let headingSize =
				section.headingSize === "xxs"
					? { base: "sm", md: "sm" }
					: section.headingSize === "xs"
					? { base: "sm", md: "md" }
					: section.headingSize === "sm"
					? { base: "md", md: "xl" }
					: section.headingSize === "md"
					? { base: "lg", md: "2xl" }
					: section.headingSize === "lg"
					? { base: "xl", md: "3xl" }
					: section.headingSize === "xl"
					? { base: "2xl", md: "4xl" }
					: section.headingSize === "xxl"
					? { base: "3xl", md: "5xl" }
					: section.headingSize === "xxxl"
					? { base: "4xl", md: "6xl" }
					: section.headingSize === "xxxxl"
					? { base: "5xl", md: "7xl" }
					: section.headingSize === "14xl"
					? { base: "7xl", md: "14xl" }
					: section.headingSize;
			return (
				<Heading
					as={section.headingTag}
					size={headingSize}
					color={section.textColor}
					textAlign={section.textAlignment}
					{...blockSpacing(
						section.blockPadding,
						section.mobileBlockPadding,
						section.blockMargin,
						section.mobileBlockMargin
					)}
				>
					{section.animateCount ? (
						<InView as="span" onChange={(inView) => setInView(inView)}>
							<StatCounter inView={inView} content={section.heading} />
						</InView>
					) : (
						!!section?.heading && parse(section.heading)
					)}
				</Heading>
			);

		case "form":
			form = !!section.form ? section.form[0] : null;

			formData = !!forms
				? forms.find(({ formId }) => formId === form.formDetails[0].formId)
						.formDetails
				: null;

			//console.log(section);
			//console.log(formData);

			return (
				<FormSection
					form={form}
					formData={formData}
					maxWidth={section.maxWidth}
					textColor={section.textColor}
					legacyBackgroundColor={section.legacyBackgroundColor}
					formHeading={section.formHeading}
					formDescription={section.formDescription}
					usePlaceholders={section.usePlaceholders}
					buttonBackgroundColor={section.buttonBackgroundColor}
					buttonTextColor={section.buttonTextColor}
				/>
			);

		case "accordion":
			return <Accordion accordionContent={section.accordionContent} />;

		case "tag":
			return <Tag tagContent={section.tagContent} />;

		case "tabs":
			return (
				<TabsBlock
					children={section.children}
					activeTabTextColor={section.activeTabTextColor}
					inactiveTabTextColor={section.inactiveTabTextColor}
					activeTabColor={section.activeTabColor}
					inactiveTabColor={section.inactiveTabColor}
					//backgroundColor={section.backgroundColor}
					headingSize={section.headingSize}
					maxWidth={section.maxWidth}
				/>
			);

		case "contentSlider":
			return <ContentSlider children={section.children} />;

		case "iconList":
			return (
				<IconList
					listItems={section.listItems}
					iconSource={section.iconSource}
					textColor={section.textColor}
					blockPadding={section.blockPadding}
					mobileBlockPadding={section.mobileBlockPadding}
					blockMargin={section.blockMargin}
					mobileBlockMargin={section.mobileBlockMargin}
				/>
			);

		case "buttonGroup":
			return (
				<ButtonGroup
					as={Flex}
					justify={section.horizontalAlignment}
					grow={1}
					spacing={section.horizontalSpacing}
				>
					{section.children.map((button, index) => {
						return (
							<ButtonBlock
								key={index}
								target={button.target}
								verticalPadding={button.verticalPadding}
								buttonBackgroundColor={button.buttonBackgroundColor}
								buttonTextColor={button.buttonTextColor}
								horizontalAlignment={button.horizontalAlignment}
								inline={true}
							/>
						);
					})}
				</ButtonGroup>
			);

		case "animation":
			return <Animation jsonFile={section.jsonFile} id={section.id} />;

		case "imageAndContent":
			image = section.image[0];

			return (
				<ImageAndContent
					image={image}
					globals={globals}
					maxWidthPixels={section.maxWidthPixels}
					children={section.children}
					reverseOrder={section.reverseOrder}
					verticalAlignment={section.verticalAlignment}
					gridGap={section.gridGap}
					roundedImage={section.roundedImage}
					blockPadding={section.blockPadding}
					mobileBlockPadding={section.mobileBlockPadding}
					blockMargin={section.blockMargin}
					mobileBlockMargin={section.mobileBlockMargin}
				/>
			);

		case "sideBySideBlade":
			backgroundImage = section.backgroundImage[0];
			mobileBackgroundImage = section.mobileBackgroundImage[0];

			return (
				<SideBySideBlade
					backgroundColor={section.backgroundColor}
					backgroundImage={backgroundImage}
					mobileBackgroundImage={mobileBackgroundImage}
					maxWidth={section.maxWidth}
					verticalAlignment={section.verticalAlignment}
					reverseOrderOnMobile={section.reverseOrderOnMobile}
					children={section.children}
					isFirstPageSection={isFirst}
					contentSpacing={section.contentSpacing}
					columnSpacing={section.columnSpacing}
					bladeVerticalPadding={section.bladeVerticalPadding}
					//sectionNav={sectionNav}
				/>
			);

		case "callToAction":
			const cta = !!callToActions
				? callToActions.find(({ sectionId }) => sectionId === section.id)?.cta
				: null;

			return <CallToActionBlock cta={cta} />;
		case "countdownTimer":
			return (
				<CountdownTimer
					countdownDate={section.countdownDate}
					textColor={section.textColor}
					backgroundColor={section.backgroundColor}
					borderColor={section.borderColor}
					blockPadding={section.blockPadding}
					mobileBlockPadding={section.mobileBlockPadding}
					blockMargin={section.blockMargin}
					mobileBlockMargin={section.mobileBlockMargin}
				/>
			);

		case "reviews":
			/*let firstColumn = [];
			let secondColumn = [];
			let thirdColumn = [];

			allReviews.map((obj) => {
				obj.text = `<p>"${obj.title}"</p>`;
				obj.stars = `${obj.starRating}Stars`;
				obj.target = {
					element: null,
					target: "",
					text: "Read Review",
					title: null,
					type: "custom",
					url: `https://www.gartner.com/reviews/market/email-security/vendor/abnormal-security/product/abnormal-security/review/view/${obj.reviewId}`,
				};
			});

			for (let i = 0; i < allReviews.length; i += 3) {
				allReviews[i] && firstColumn.push(allReviews[i]);
				allReviews[i + 1] && secondColumn.push(allReviews[i + 1]);
				allReviews[i + 2] && thirdColumn.push(allReviews[i + 2]);
			}
			const sortedArray = [
				{ children: firstColumn },
				{ children: secondColumn },
				{ children: thirdColumn },
			];*/
			return (
				<Reviews
					//borderType={section.borderType}
					//borderColor={section.borderColor}
					backgroundColor={section.backgroundColor}
					textColor={section.textColor}
					allReviews={allReviews}
				/>
			);

		default:
			return <div>{section.typeHandle}</div>;
	}
};

export default DefaultBlocks;
