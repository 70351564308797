import { Box } from "@chakra-ui/react";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";
import Lottie from "react-lottie";

import dynamic from "next/dynamic";

const LazyLottie = dynamic(() =>
	import("react-lottie").then((mod) => mod.default)
);

const Animation = ({ jsonFile, id = null }) => {
  const [ref, inView] = useInView({
    threshold: 0,
  });

  return (
    <Box id={id} className={clsx("Animation relative w-full")} ref={ref}>
			<LazyLottie
				options={{
					loop: false,
					path:
						process.env.NODE_ENV === "development"
							? "/servd-volumes/images/" + jsonFile[0].path
							: jsonFile[0].url,
				}}
				isStopped={!inView}
			/>
		</Box>
	);
};

export default Animation;
