import SitemapItem from "@components/SitemapItem";
import Target from "@components/Target";
import clsx from "clsx";
import React from "react";

interface IProps {
  id?: string;
  heading?: string;
  children?: {
    id: string;
    target: {
      text: string;
      url: string;
    };
    level: number;
    children?: any;
  }[];
}

const Sitemap = ({
  id = null,
  children,
  heading,
}: IProps) => {
  //console.log(children.length)
  return (
    <div id={id} className={clsx(
      "px-5 mb-15 max-w-screen-lg mx-auto mb-7.5 flex-grow"
    )}>
      <h2 className={clsx(
        "text-3xl my-3"
      )}>{heading}</h2>
      {children.map((item, index) => (
        <div key={item.id} className={clsx(
          {"ml-3 text-2xl mb-3" : item.level == 1},
          {"ml-3 text-xl" : item.level == 2},
          {"ml-5 text-lg" : item.level == 3},
          {"mb-3" : item.level == 3 && index == (children.length - 1)}
        )}>
          <SitemapItem target={item.target} level={item.level}>
            {item?.children}
          </SitemapItem>
        </div>
      ))}
    </div>
  );
};

export default Sitemap;
