import { Box, Button, Flex, SimpleGrid } from "@chakra-ui/react";

import Link from "next/link";

import clsx from "clsx";

import { ArrowRight } from "@abnormalComponents/Icons";
import SecondaryButton from "@abnormalComponents/SecondaryButton";
import fourstars from "@images/4stars.svg";
import fivestars from "@images/5stars.svg";
import parse from "html-react-parser";
import Image from 'next/image'
import React from "react";

interface IProps {
  backgroundColor: string;
  textColor: string;
  allReviews: any[];
}

const Reviews = ({ backgroundColor, textColor, allReviews }: IProps) => {
	//this is here for future needs
	/*const handleClick = (text, url) => {
		window.dataLayer?.push({
			event: "event",
			eventProps: {
				category: "Button Click",
				action: eventDescription ? eventDescription : text,
				label: url,
			},
		});
	};*/

	return (
		<Box className="Reviews__Container" backgroundColor={backgroundColor}>
			<SimpleGrid mx="auto" columns={{ base: 1, md: 3 }} maxW="1240px">
				{allReviews.map((review, index) => {
					let target = {
						element: null,
						target: "_blank",
						text: "Read Review",
						title: null,
						type: "custom",
						url: `https://www.gartner.com/reviews/market/email-security/vendor/abnormal-security/product/abnormal-cloud-email-security-platform/review/view/${review.reviewId}`,
					};
					return (
						<Box
							textAlign="center"
							key={index}
							w="100%"
							className={`"Review" ${index}`}
							textColor={textColor}
							px="10"
							pb={10}
						>
							<Image
								src={
									review.starRating === 5 || review.starRating == null
										? fivestars
										: fourstars
								}
								layout="fixed"
								alt="5-stars"
								width={review.starRating === 4 ? 100 * 0.8 : 100}
              />
              {!!review?.title && (
                <p
                  className={clsx({
                    "text-xl":
                      review.title.length < 45 && review.title.length > 30,
                    "text-2xl": review.title.length <= 30,
                  })}
                >
                  "{parse(review.title)}"
                </p>
              )}

							{review.reviewId && (
								<div className={clsx("text-xs mt-3")}>
									<Button
										bg={"transparent"}
										color={"#6863F2"}
										href={target?.url}
										as={target?.url && Link}
										//this is here for future needs
										/*onClick={
											target && (() => handleClick(target.text, target.url))
										}*/
										rightIcon={<ArrowRight />}
										target={target?.target}
										_hover={{
											bg: "transparent",
											".chakra-button__icon": {
												transform: "translateX(.25rem)",
											},
										}}
									>
										{target?.text}
									</Button>
								</div>
							)}
						</Box>
					);
				})}
			</SimpleGrid>
		</Box>
	);
};

export default Reviews;
