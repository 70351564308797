import PageSectionBlocks from "@abnormalComponents/PageSectionBlocks";
import { Box, Link } from "@chakra-ui/react";
import clsx from "clsx";
import NextLink from "next/link";
import { blockSpacing } from "../lib/getBlockSpacing";
import { getStyleObjectFromString } from "../lib/getStyleObjectFromString";

const ColumnContainer = (props) => {
	const {
		gridPosts,
		forms,
		globals,
		containerMaxWidth,
		gridColumns,
		gridMode,
		gridColumnSpan,
		columnWidth,
		isFirstContainer,
		target,
		children,
		backgroundFullWidth,
		backgroundImage,
		backgroundImageBlur,
		columnWidthPx,
		constrainToMainContainer,
		horizontalAlignment,
		...otherProps
	} = props;
	if (target?.url) {
		return (
			<NextLink href={target.url} passHref legacyBehavior>
				<Link {...otherProps}>
					{children?.map((child, index) => {
						return (
							<PageSectionBlocks
								section={child}
								key={index}
								subColumn={child.typeHandle == "column" && true}
								//children={children}
								gridPosts={gridPosts}
								forms={forms}
								globals={globals}
								containerMaxWidth={containerMaxWidth}
								gridColumns={gridColumns}
								gridMode={gridMode}
								columnWidth={columnWidth}
								isFirstContainer={isFirstContainer}
							/>
						);
					})}
				</Link>
			</NextLink>
		);
	} else {
		return (
			<>
				<Box
					zIndex={5}
                    /* use pr and pl NOT px, so that otherProps will override when values are set in craft */
					pr={{ base: 5, md: 0 }}
                    pl={{ base: 5, md: 0 }}
					w="100%"
					maxWidth={constrainToMainContainer && columnWidthPx}
					{...otherProps}
					gridColumn={gridMode ? "span " + gridColumnSpan : null}
				>
					{children?.map((child, index) => {
						return (
							<PageSectionBlocks
								section={child}
								key={index}
								subColumn={child.typeHandle == "column" && true}
								//children={children}
								gridPosts={gridPosts}
								forms={forms}
								globals={globals}
								containerMaxWidth={containerMaxWidth}
								gridColumns={gridColumns}
								gridMode={gridMode}
								columnWidth={columnWidth}
								isFirstContainer={isFirstContainer}
							/>
						);
					})}
				</Box>
			</>
		);
	}
};

interface IProps {
	children: object;
	columnWidth: string;
	gridColumnSpan: number;
	constrainToMainContainer: boolean;
  blockPadding: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  }[];
  mobileBlockPadding: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  }[];
  blockMargin: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  }[];
  mobileBlockMargin: {
    top: string;
    right: string;
    bottom: string;
    left: string;
  }[];
	target: any;
	subColumn: boolean;
	gridMode?: boolean;
	isFirstContainer?: boolean;
	gridColumns?: number;
	verticalAlignment?: string;
	backgroundColor: string;
	borderColor: string;
	borderRadius: string;
	borderStyle: string;
	borderWidth: string;
	containerMaxWidth: string;
	gridPosts: object;
	forms: object;
	globals: object;
	backgroundImage: any;
	backgroundImageBlur: any;
	backgroundFullWidth: Boolean;
	contentDirection: Boolean;
	horizontalAlignment: string;
	customCss: string;
	gridGap: string;
}

const Column = ({
	children,
	columnWidth,
	constrainToMainContainer,
	blockPadding,
	mobileBlockPadding,
	blockMargin,
	mobileBlockMargin,
	gridMode = false,
	gridColumns,
	gridColumnSpan,
	verticalAlignment = "top",
	backgroundColor,
	borderColor,
	borderRadius,
	borderStyle,
	borderWidth,
	containerMaxWidth,
	target,
	gridPosts,
	forms,
	globals,
	isFirstContainer,
	backgroundImage,
	backgroundImageBlur,
	backgroundFullWidth,
	horizontalAlignment = "start",
	contentDirection = false,
	customCss,
	gridGap,
}: IProps): JSX.Element => {
	const columnWidthPercent =
		columnWidth !== "full" &&
		columnWidth !== "fitContents" &&
		columnWidth !== "fillSpace"
			? eval(columnWidth)
			: 0;
	const columnWidthPx = 1200 * columnWidthPercent;
	//console.log(containerMaxWidth);
	//console.log(backgroundImage)
	return (
		<ColumnContainer
			as="div"
			justifyContent={horizontalAlignment}
			target={target}
			pos="relative"
			display="flex"
			sx={!!customCss && getStyleObjectFromString(customCss)}
			flexShrink={columnWidth === "fitContents" ? 1 : null}
			flex={columnWidth === "fillSpace" ? "1 1 0%" : null}
			gap={gridGap && gridGap !== "none" ? { base: "sm", md: gridGap } : null}
			w={
				columnWidthPercent
					? {
							base: "100%",
							md: !!columnWidthPercent && columnWidthPercent * 100 + "%",
					  }
					: {
							base: "100%",
							md: "unset",
					  }
			}
			flexWrap="wrap"
			flexDirection={{
				base: contentDirection ? "column" : null,
				md: contentDirection ? "column" : null,
			}}
			borderStyle={borderStyle}
			bgImage={backgroundImage?.url}
			bgSize="cover"
			bgRepeat="no-repeat"
			borderRadius={(borderStyle && borderRadius) || null}
			alignContent={verticalAlignment}
			borderColor={borderStyle && borderColor}
			backgroundColor={backgroundColor}
			borderWidth={borderStyle && borderWidth}
			h={gridMode && "100%"}
			maxW={
				columnWidth !== "full" &&
				constrainToMainContainer &&
				!backgroundFullWidth &&
				columnWidthPx /*Add Conditional for backgroundFullWidth */
			}
			className={clsx("Column")}
			gridPosts={gridPosts}
			forms={forms}
			globals={globals}
			containerMaxWidth={containerMaxWidth}
			gridColumns={gridColumns}
			gridMode={gridMode}
			gridColumnSpan={gridColumnSpan}
			columnWidth={columnWidth}
			isFirstContainer={isFirstContainer}
			position="relative"
			children={children}
			backgroundFullWidth={backgroundFullWidth}
			backgroundImage={backgroundImage}
			backgroundImageBlur={backgroundImageBlur}
			columnWidthPx={columnWidthPx}
			constrainToMainContainer={constrainToMainContainer}
			{...blockSpacing(
				blockPadding,
				mobileBlockPadding,
				blockMargin,
				mobileBlockMargin
			)}
		/>
	);
};

export default Column;
