import clsx from "clsx";
import parse from "html-react-parser";
import Image from 'next/image';

import { ImgProps } from "@chakra-ui/react";
import HeadingTag from "@components/HeadingTag";
import SecondaryButton from "@components/SecondaryButton";
import SideBySide from "@components/SideBySide";
import IImage from "@sharedInterfaces/Image.interface";

interface IProps {
  legacyBackgroundColor?: string;
  backgroundImage?: IImage;
  backgroundImageBlur?: IImage;
  legacyButtonBackgroundColor?: string;
  buttonTextColor?: string;
  heading?: string;
  headingTag?: "h2" | "h1" | "h3" | "h4";
  headingSize?: "lg" | "xxl" | "xl" | "md" | "sm";
  image?: IImage;
  mediaPaddingTop?: boolean;
  mediaPosition?: "left" | "right";
  mobileImage?: IImage;
  target?: {
    url?: string;
    title?: string;
    [key: string]: any;
  };
  text?: string;
  textColor?: string;
  videoStyle?: "inline" | "autoplayInline" | "modal" | "slideDown";
  videoUrl?: string;
  indentText?: boolean;
  form?: any;
}

const CallToAction = ({
  legacyBackgroundColor,
  backgroundImage,
  backgroundImageBlur,
  legacyButtonBackgroundColor,
  buttonTextColor,
  heading,
  headingTag = "h2",
  headingSize = "lg",
  image,
  mediaPaddingTop = true,
  mediaPosition = "left",
  mobileImage,
  target,
  text,
  textColor,
  videoStyle,
  videoUrl,
  indentText,
  form,
}: IProps) => {
  return (
    <div className={clsx("CallToAction")}>
      {(image || !!form) && (
        <div className={clsx("CallToAction__sideBySide")}>
          <SideBySide
            legacyBackgroundColor={legacyBackgroundColor}
            backgroundImage={backgroundImage}
            backgroundImageBlur={backgroundImageBlur}
            legacyButtonBackgroundColor={legacyButtonBackgroundColor}
            buttonTextColor={buttonTextColor}
            heading={heading}
            headingTag={headingTag}
            headingSize={headingSize}
            image={image}
            indentText={indentText}
            mediaPosition={mediaPosition}
            mediaPaddingTop={mediaPaddingTop}
            mobileImage={mobileImage || image}
            target={target}
            text={text}
            textColor={textColor}
            videoStyle={videoStyle}
            videoUrl={videoUrl}
            form={form}
          />
        </div>
      )}
      {!image && !form && (
        <div
          className={clsx(
            "CallToAction__content",
            "py-15 lg:py-44",
            "relative"
          )}
          style={{ backgroundColor: legacyBackgroundColor, color: textColor }}
        >
          <div className={clsx("relative z-20")}>
            <HeadingTag
              tag={headingTag}
              className={clsx(
                "CallToAction__heading",
                "text-3xl lg:text-4xl 3xl:text-5xl",
                "text-center",
                "max-w-3xl mb-7.5 lg:mb-15 mx-auto px-7.5"
              )}
            >
              {heading}
            </HeadingTag>

            {!!text && (
              <div
                className={clsx(
                  "CallToAction__text",
                  "max-w-3xl",
                  "text-center",
                  "mx-auto px-7.5"
                )}
              >
                {parse(text)}
              </div>
            )}
            {!!target && !!target.url && (
              <div
                className={clsx(
                  "CallToAction__secondaryButton",
                  "max-w-3xl",
                  "text-center",
                  "mt-7.5 lg:mt-15 mx-auto"
                )}
              >
                <SecondaryButton target={target} />
              </div>
            )}
          </div>
          {!!backgroundImage?.url && (
            <div
              className={clsx(
                "SideBySide__backgroundImage",
                "absolute inset-0 z-0"
              )}
            >
              <Image
                src={backgroundImage.url}
                layout="fill"
                objectFit="cover"
                alt={backgroundImage.title}
                draggable={false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CallToAction;
