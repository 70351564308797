import Target from "@components/Target";
import clsx from "clsx";
import React from "react";

interface IProps {
  id?: string;
  target: {
    text: string;
    url: string;
  };
  level: number;
  children?: any;
}

const SitemapItem = ({
  id = null,
  children,
  target,
  level
}: IProps) => {
  return (
    <div id={id}>
      <Target target={target} className={clsx(
        "mb-3",
        {"ml-3 text-2xl" : level == 1},
        {"ml-3 text-xl font-bold" : level == 2},
        {"ml-5 text-lg" : level == 3}
      )}>
        {target.text}
      </Target>
      {children && children.map((item, index) => (
        <div key={item.id} className={clsx(
          {"ml-3 text-2xl" : item.level == 1},
          {"ml-3 text-xl" : item.level == 2},
          {"ml-5 text-lg" : item.level == 3},
          {"mb-3" : item.level == 3 && index == (children.length - 1)}
        )}>
          <SitemapItem target={item.target} level={item.level}>
            {item.children}
          </SitemapItem>
        </div>
      ))}
    </div>
  );
};

export default SitemapItem;
